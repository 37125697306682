/**=====================
  3.13 Dashboard_default CSS Start
==========================**/
//index dashboard css start

  .form-control-plaintext {
    width: auto !important;
  }
  
  @keyframes bounce-effect {
    0% {
      transform: translateY(0);
      transition: 0.5s;
    }
  
    50% {
      transform: translateY(-5px);
      transition: 0.5s;
    }
  
    100% {
      transform: translateY(0);
      transition: 0.5s;
    }
  }
  
  @keyframes round {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(1.2);
    }
  
    100% {
      transform: scale(1);
    }
  }
  //badge 
  @each $badge-light-name,
  $badge-light-color in (primary, $primary-color),
  (secondary, $secondary-color),
  (success, $success-color),
  (danger, $danger-color),
  (info, $info-color),
  (light, $badge-light-color),
  (dark, $dark-color),
  (warning, $warning-color) {
    .badge-light-#{$badge-light-name} {
      background-color: lighten($badge-light-color, 38%);
      span{
        color: $badge-light-color !important;
      }
    }
  }
  
  .badge-light-light {
    background-color: lighten($badge-light-color, 55%);
  }
  
  .badge-light-primary { 
    background-color: rgba($primary-color, 0.1);
  }
  
  .badge-light-success {
    background-color: rgba($success-color, 0.15);
  }
  
  .badge-light-danger {
    background-color: rgba($danger-color, 0.3);
  }
  
  .badge-light-info{
    background-color: rgba($info-color, 0.3);
  } 
  
  .badge-light-secondary {
    background-color: rgba($secondary-color, 0.08);
  }
  
  // select2
  .badge-light-warning {
    background-color: rgba($warning-color, 0.1);
  }
  
  .table {
    thead {
      th {
        border-bottom: 2px solid $light-gray;
      }
    }
  }
  
  .overall-card {
    .balance-card {
      @media (max-width:1712px) {
        padding: 12px;
      }
    }
  
    @media (max-width: 1662px) and (min-width:1600px) {
      .col-xl-9 {
        width: 73%;
      }
  
      .col-xl-3 {
        width: 27%;
      }
    }
  
    @media (max-width: 1650px) and (min-width:1600px) {
      .col-xl-9 {
        width: 72%;
      }
  
      .col-xl-3 {
        width: 28%;
      }
    }
  
    @media (max-width: 1600px) and (min-width:1500px) {
      .col-xl-9 {
        width: 70%;
      }
  
      .col-xl-3 {
        width: 30%;
      }
    }
  
    @media (max-width: 1500px) and (min-width:1200px) {
      .col-xl-9 {
        width: 65%;
      }
  
      .col-xl-3 {
        width: 35%;
      }
    }
  }
  
  /* Clock styles */
  .clockbox {
    margin-bottom: -5px;
  
    svg {
      width: 45px;
      height: 45px;
  
      @media (max-width: 767px) {
        height: 36px;
      }
    }
  }
  

  
  .circle {
    fill: $white;
    stroke: rgba($white, 0.4);
    stroke-width: 80px;
    stroke-miterlimit: 10;
  }
  
  .mid-circle {
    fill: #000;
  }
  
  .hour-marks {
    fill: none;
    stroke: $theme-body-sub-title-color;
    stroke-width: 9;
    stroke-miterlimit: 10;
  }
  
  .hour-hand {
    fill: none;
    stroke: #ff7e00;
    stroke-width: 17;
    stroke-miterlimit: 10;
  }
  
  .minute-hand {
    fill: none;
    stroke: #ff7e00;
    stroke-width: 11;
    stroke-miterlimit: 10;
  }
  
  .second-hand {
    fill: none;
    stroke: $warning-color;
    stroke-width: 15px;
    stroke-miterlimit: 10;
  }
  
  .sizing-box {
    fill: none;
  }
  
  #hour,
  #minute,
  #second {
    transform-origin: 300px 300px;
    transition: transform .5s ease-in-out;
  }
  
  .widget-grid {
    .height-equal {
      @media (min-width: 1400px) {
        min-height: unset !important;
      }
    }
  
    >.col-xxl-auto {
      @media (min-width: 1400px) {
        width: 22.22%;
      }
    }
  }
  
  .order-chart {
    >div {
      margin-top: -25px;
      margin-bottom: -43px;
    }
  
    svg {
      path {
        clip-path: inset(7% 0% 0% 0% round 0.6rem);
      }
    }
  }
  
  .growth-wrap {
    .card-header {
      position: relative;
      z-index: 1;
    }
  }
  
  .growth-wrapper {
    >div {
      margin-top: -54px;
      margin-bottom: -25px;
  
      @media (max-width: 1481px) {
        margin-top: -50px;
      }
    }
  }
  
  .profit-chart {
    >div {
      margin-top: -45px;
      margin-bottom: -48px;
    }
  
    .apexcharts-canvas {
      .apexcharts-tooltip-text-label {
        display: none;
      }
    }
  }
  // new admin css start 
  .card{
    .card-footer{
      .common-space{
        a{
          color: $theme-body-font-color;
        }
        &:hover{
          a{
            color: $primary-color;
          }
        }
      }
    }
  }
  .sales-wrapper{
    .apexcharts-xaxistooltip{
      display: none;
    }
  }
  .total-order{ 
    .apexcharts-xaxistooltip{
      display: none;
    }
  }
  .sales-chart{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sales-chart-dropdown{
    display: flex;
    gap: 15px;
    align-items: center;
    .sales-chart-dropdown-select{
      display: flex;
      justify-content: end;
      gap: 15px;
    }
  }
  .total-revenue{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      // color: var(--body-font-color);
      font-weight: 500;
    }
    .update-data{
      position: relative;
      &::before{
        content: "";
        position: absolute;
        height: 4px;
        width: 4px; 
        bottom: 3px;
        left: -15px;
        bottom: 9px;
        border-radius: 15px;
        background-color: $light-txt-color;
      }
    }
    a{
      font-weight: 600;
    }
  }
  .total-chart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -45px;
    margin-top: -20px;
    .data-grow{
      svg{ 
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      span{ 
        color: $theme-body-font-color;
      }
    }
  }
  .special-Offer{
    background-image: url(../images/dashboard/Banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 363px;
    border-radius: 8px;
    width: 100%; 
    background-position: center;
    h4{
      font-weight: 700;
      font-size: 22px;
      color: $white;
      text-align: center;
    }
    p{
      font-size: 12px;
      font-weight: 500; 
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    } 
    .offer-contain{
      padding-top: 45px;
      text-align: center;
      p{
        margin: 0px 20px ;
      }
    }
  }
  
  .ecommerce-banner{
    background-image: url(../images/dashboard/Dashboard-Ecommerce-banner.gif);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%; 
    height: 190px;
  }
  .product-widget{
    &:hover{ 
      transform: translateY(-5px);
      transition: 0.5s;
    }
  }
  .new-product{
    padding: 16px !important;
    svg{
      cursor: pointer;
    }
    &:hover{
      .product-cost{
        .add-product{
          svg{ 
            animation: tada 1.5s ease infinite;
          }
        }
      }
    }
    .product-icon{
      height: 50px;
      width: 50px;
      border-radius: 6px;
    }
    .product-cost{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-product{
        display: flex;
        align-items: center;
        gap: 16px;
        h6 {
          display:-webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        span{
          display:-webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; 
        }
      }
      .product-icon{
        svg{ 
          height: 25px; 
          margin: 12px;
          width: 25px;
        }
      }
    }
  }
  
  .product-costing{
    margin-bottom: 14px;
    border-bottom: 1px dashed $light-color;
    p{ 
      font-size: 14px;
      font-weight: 500;
    }
    span{
      margin-bottom: 14px;
      display:-webkit-box;
      -webkit-line-clamp: 1; 
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:last-child{
      span{
        margin-bottom: 0px; 
      }
      border: none;
      margin-bottom: 0px;
    } 
    .product-icon{
      height: 50px;
      width: 50px;
      border-radius: 6px;
    }
    &:last-child{
      .costing-span{
        margin-bottom: 0px;
      }
    }
    .product-cost{
      display: flex;
      gap: 15px;
      margin-bottom: 8px;
      .product-icon{
        svg{
          height: 25px;
          margin: 12px;
          width: 25px;
          cursor: pointer;
        }
      }
    }
    &:hover{
      .product-cost{
        svg{
          animation: tada 1.5s ease infinite;
        }
      }  
    }
  }
  .sale-history-card{
    margin-bottom: 16px;
    border-bottom:1px solid $light-color;
    &:last-child{
      margin-bottom: 0px;
      border: none;
    }
    &:hover{
      .history-price{
        a{
          color: $primary-color !important;
        }
      }
    }
    .history-price{
      display: flex;
      align-items: center;
      justify-content: space-between;
      a{ 
        display:-webkit-box;
        -webkit-line-clamp: 1; 
        -webkit-box-orient: vertical; 
        overflow: hidden;
        color: $theme-body-font-color ;
      }
    }
    &:last-child{
      .state-time{
        margin-bottom: 0px;
      }
    }
    .state-time{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }
  .table-order{
    .order-table-images{
      height: 35px;
    }
    .product-name{
      display: flex;
      align-items: center;
      gap: 15px;
      img{
        border-radius: 4px;
      }
    }
    .invoice-icon{
      height: 24px;
      width: 24px;
    }
    .latest-orders{
      thead{
        background-color: rgba($light-color,0.3);
        tr{
          th{ 
            padding: 20px; 
            &:nth-child(1){
              min-width: 190px; 
            } 
            &:nth-child(2){ 
              min-width: 200px;
              padding: 20px 0px;
            }
            &:nth-child(3){
              min-width: 130px;
              padding: 20px 0px;
            }
            &:nth-child(4){
              min-width: 90px;
              padding: 20px 0px;
            } 
            &:nth-child(5){
              min-width: 50px;
            }
            &:first-child{
              border-top-left-radius: 5px !important;
              border-bottom-left-radius: 5px !important;
            }
            &:last-child{
              border-top-right-radius: 5px !important;
              border-bottom-right-radius: 5px !important;
            }
          }
        }
      }
      tbody{
        tr{
          border-bottom: 1px solid $light-color;
          td{
            &:nth-child(4){
              .badge { 
                padding: 7px 18px;
              }
            } 
            &:last-child {
              text-align: center;
            }
            padding-top: 17px;
            padding-bottom: 17px;
            p { 
              color: var(--body-font-color);
              font-weight: 500; 
            } 
            .status-process {
              border-radius: 15px;
              padding: 12px;
            }
            &:last-child {
              .product-sub {
                text-align: center;
              }
            }
          }
          &:last-child{
            border: none; 
            td{
              padding-bottom: 0px;
              overflow: hidden;
            } 
          }
        }
        tr{
          td{
            a{ 
              color: $theme-body-font-color;
            }
          }
          &:hover{
            td{ 
              a{
                color: var(--theme-deafult) !important;
              }
            }
          }
        }
      }
    }
    .order-table{
      thead{
        background-color: rgba($light-color,0.3);
        tr{
          th{ 
            padding: 20px; 
            &:nth-child(1){ 
              min-width: 190px; 
            } 
            &:nth-child(2){ 
              min-width: 200px;
              padding: 20px 0px;
            }
            &:nth-child(3){
              min-width: 130px;
              padding: 20px 0px;
            }
            &:nth-child(4){
              min-width: 90px;
              padding: 20px 0px;
            } 
            &:nth-child(5){
              min-width: 50px;
            }
            &:first-child{
              border-top-left-radius: 5px !important;
              border-bottom-left-radius: 5px !important;
            }
            &:last-child{
              border-top-right-radius: 5px !important;
              border-bottom-right-radius: 5px !important;
            }
          }
        }
      }
      tbody{
        tr{
          border-bottom: 1px solid $light-color;
          td{
            &:nth-child(4){
              .badge {
                padding: 9px 18px; 
              } 
            } 
            &:last-child {
              text-align: center;
            }
            padding-top: 17px;
            padding-bottom: 17px;
            p { 
              color: var(--body-font-color);
              font-weight: 500; 
            } 
            .status-process {
              border-radius: 15px;
              padding: 12px;
            }
            &:last-child {
              .product-sub {
                text-align: center;
              }
            }
          }
          &:last-child{
            border: none; 
            td{
              padding-bottom: 0px;
              // overflow: hidden;
            } 
          }
        }
        tr{
          td{
            a{ 
              color: $theme-body-font-color;
            }
          }
          &:hover{
            td{ 
              .product-name{
                .product-sub{
                  a{
                    color: var(--theme-deafult) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .revenueproductwidget{
    margin: 230px 0px 0px 0px;
  } 
  .revenueproduct{
    .apexcharts-canvas{
      .apexcharts-legend{
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
    .apexcharts-canvas {
      .apexcharts-legend-marker {
        border-radius: 4px !important;
      }
      .apexcharts-legend-text{
        font-size: 14px !important; 
      }
    } 
  }
  .widget-1 {
    background-image: url(../images/dashboard/widget-bg.png);
    background-size: cover;
    margin-bottom: 25px;
  
    i {
      font-weight: 700;
      font-size: 12px;
    }
  
    .f-w-500 {
      display: flex;
      align-items: center;
  
      @media (max-width: 1580px) and (min-width: 1200px) {
        display: none;
      }
    }
  
    .card-body {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 18px 25px;
  
      @media (max-width: 1720px) {
        padding: 18px;
      }
    }
  
    .widget-round {
      position: relative;
      display: inline-block;
      border-width: 1px;
      border-style: solid;
      border-radius: 100%;
  
      .bg-round {
        width: 56px;
        height: 56px;
        box-shadow: $widget-shadow;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin: 6px;
        position: relative;
        z-index: 1;
  
        svg {
          width: 24px;
          height: 24px;
        }
  
        .half-circle {
          height: 52px;
          position: absolute;
          left: -9px;
          width: 35px;
          bottom: -8px;
          background: $white;
          z-index: -1;
        }
  
        @media (max-width:1600px) {
          width: 40px;
          height: 40px;
  
          svg {
            width: 22px;
            height: 22px;
          }
  
          .half-circle {
            height: 40px;
            left: -10px;
            width: 30px;
            bottom: -8px;
          }
        }
      }
  
      &.primary {
        border-color: var(--theme-deafult);
      }
  
      &.secondary {
        border-color: var(--theme-secondary);
      }
  
      &.success {
        border-color: $success-color;
      }
  
      &.warning {
        border-color: $warning-color;
      }
    }
  
    .widget-content {
      display: flex;
      align-items: center;
      gap: 15px;
  
      @media (max-width:1600px) {
        gap: 10px;
      }
  
      h4 {
        margin-bottom: 4px;
      }
    }
  
    &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
  
      .widget-round {
        .svg-fill:not(.half-circle) {
          animation: tada 1.5s ease infinite;
        }
      }
    }
  }
  
  .widget-with-chart {
    .card-body {
      align-items: center;
    }
  }
  
  .profile-box {
    background: $gradient-bg-primary;
    color: $white;
  
    .cartoon {
      position: absolute;
      bottom: -15px;
      right: 20px;
      animation: bounce-effect 5s infinite ease-in;
  
      [dir="rtl"] & {
        right: unset !important;
        left: 5px;
      }
  
      @media (max-width: 1660px) {
        right: 30px;
        text-align: right;
  
        [dir="rtl"] & {
          left: -6px;
          text-align: left;
        }
  
        img {
          width: 80%;
        }
      }
  
      @media (max-width: 1500px) {
        img {
          width: 60%;
        }
      }
  
      @media (max-width: 767px) {
        right: 10px;
  
        [dir="rtl"] & {
          left: -10px;
        }
  
        img {
          width: 52%;
        }
      }
  
      @media (max-width: 575px) {
        right: 30px;
  
        [dir="rtl"] & {
          left: 10px;
        }
  
        img {
          width: 48%;
        }
      }
    }
  
    .greeting-user {
      p {
        width: 60%;
  
        @media (max-width: 1500px) {
          width: 70%;
        }
  
        @media (max-width: 1199px) {
          width: 80%;
        }
  
        @media (max-width: 767px) {
          width: 98%;
        }
  
        @media (max-width: 600px) {
          width: 100%;
        }
  
        @media (max-width: 575px) {
          width: 98%;
        }
      }
    }
  
    .whatsnew-btn {
      margin-top: 3.5rem;
  
      @media (max-width: 1500px) {
        margin-top: 1.7rem;
      }
  
      @media (max-width: 991px) {
        margin-top: 3.5rem;
      }
  
      @media (max-width: 638px) {
        margin-top: 2rem;
      }
  
      .btn {
        @media (max-width: 767px) {
          padding: 6px 10px;
        }
      }
    }
  }
  
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  
    a {
      i {
        font-size: 11px;
        margin-bottom: -2px;
      }
  
      &.btn {
        padding: 8px 12px;
  
        @media (max-width:575px) {
          padding: 5px;
        }
  
        &.badge-light-primary {
          &:hover {
            background-color: var(--theme-deafult);
          }
        }
      }
    }
  }
  
  .user-details {
    display: flex;
    align-items: center;
    gap: 15px;
  
    @media (max-width: 1399px) {
      gap: 6px;
    }
  
    .customers {
      width: 200px;
    }
  
    @media (max-width: 575px) {
      flex-wrap: wrap;
    }
  }
  
  .papernote-wrap {
    .banner-img {
      border-radius: 15px;
      width: 100%;
    }
  
    .note-labels {
      display: flex;
      align-items: center;
  
      >* {
        padding: 3px 0;
      }
  
      ul {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
  
        @media (max-width: 575px) {
          gap: 4px;
        }
      }
  
      .badge {
        font-size: 14px;
        padding: 6px 12px;
  
        @media (max-width: 575px) {
          font-size: 12px;
          padding: 5px 6px;
        }
      }
  
      .last-label {
        padding-left: 16px;
        margin-left: 16px;
        border-left: 1px dashed rgba($theme-body-sub-title-color, 0.7);
  
        [dir="rtl"] & {
          padding-left: unset;
          margin-left: unset;
          margin-right: 16px;
          padding-right: 16px;
          border-left: none;
          border-right: 1px dashed rgba($theme-body-sub-title-color, 0.7);
        }
  
        @media (max-width: 575px) {
          padding-left: 8px;
          margin-left: 8px;
  
          [dir="rtl"] & {
            margin-right: 8px;
            padding-right: 8px;
          }
        }
      }
    }
  
    .light-card {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--light2);
      border: 1px solid rgba($theme-body-sub-title-color, 0.2);
    }
  }
  
  .appointment-sec {
    .card-header-right-icon {
      select {
        background-color: rgba($primary-color, 0.08) !important;
        border: none;
        color: var(--theme-deafult) !important;
        width: auto !important;
        height: 32px !important;
        border-radius: 10px;
        padding: 5px 10px;
        line-height: 50px !important;
        font-weight: 500;
        border: none;
        outline: none !important;
  
        &:hover {
          background-color: rgba($primary-color, 0.08) !important;
          color: var(--theme-deafult) !important;
        }
      }
    }
  
    .bottom-btn {
      text-align: center;
  
      a {
        font-family: $font-Montserrat;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.3px;
        text-decoration-line: underline;
        text-transform: uppercase;
      }
    }
  }
  
  .notification {
    ul {
      position: relative;
      &::before { 
        position: absolute;
        content: "";
        border: 1px dashed $light-color; 
        opacity: 0.3;
        top: 12px;
        left: 2px;
        height: calc(100% - 12px);

        [dir="rtl"] & {
          left: unset;
          right: 2px;
        }
      }
    }
  
    li {
      padding-bottom: 22px;
    }
  
    div[class*="activity-dot-"] {
      margin-top: 12px;
      animation: round 1.3s ease-in-out infinite;
    }
  
    .card {
      .d-flex {
        .w-100 {
          p {
            .badge {
              color: $white;
              margin-left: 10px;
              font-weight: 500;
            }
          }
  
          h6 {
            margin-bottom: 5px;
            position: relative;
  
            ~p {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              text-emphasis: inherit;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              width: 260px;
  
              @media (max-width: 767px) {
                -webkit-line-clamp: unset;
                text-emphasis: unset;
                white-space: unset;
                text-overflow: unset;
                display: block;
                width: unset;
                overflow: visible;
              }
            }
  
            @keyframes round {
              0% {
                transform: scale(1);
              }
  
              50% {
                transform: scale(1.2);
              }
  
              100% {
                transform: scale(1);
              }
  
            }
          }
  
          span {
            color: $theme-body-sub-title-color;
          }
        }
  
        &:nth-child(2) {
          .media-body {
            h6 {
              .dot-notification {
                background-color: $warning-color;
                border: 5px solid lighten($warning-color, 30%);
              }
            }
          }
        }
  
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  
    .date-content {
      padding: 4px 8px;
      border-radius: 5px;
    }
  }
  
  .schedule-container {
    >div {
      margin-bottom: -40px;
    }
  
    svg {
      path {
        clip-path: inset(1% 0% 0% 0% round 1.5rem);
      }
    }
  }
  
  .purchase-card {
    text-align: center;
    margin-top: 200px;
  
    @media (max-width:1560px) {
      margin-top: 170px;
    }
  
    @media (max-width:1399px) {
      margin-top: 160px;
    }
  
    @media (max-width: 500px) {
      margin-top: 110px;
    }
  
    @media (max-width: 420px) {
      margin-top: 90px;
    }
  
    img {
      margin-top: -208px;
  
      @media (max-width:1560px) {
        margin-top: -170px;
      }
  
      @media (max-width: 1399px) {
        width: 70%;
        margin: -160px auto 0 auto;
      }
  
      @media (max-width: 1366px) {
        width: 76%;
      }
  
      @media (max-width: 1199px) {
        width: 86%;
      }
  
      @media (max-width: 991px) {
        width: 72%;
      }
  
      @media (max-width: 767px) {
        width: 50%;
      }
  
      @media (max-width: 500px) {
        margin-top: -110px;
      }
  
      @media (max-width: 420px) {
        margin-top: -90px;
        width: 60%;
      }
    }
  
    .purchase-btn {
      min-width: auto;
    }
  }
  
  .user-list {
    column-count: 2;
    column-rule-style: dashed;
    column-rule-color: rgba($theme-body-sub-title-color, 0.7);
    column-gap: 120px;
    column-rule-width: 1px;
  
    @media (max-width: 1780px) {
      column-gap: 80px;
    }
  
    @media (max-width: 1660px) {
      column-gap: 40px;
    }
  
    @media (max-width: 360px) {
      column-gap: 25px;
    }
  
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
  
      @media (max-width: 1540px) {
        gap: 5px;
      }
  
      i {
        font-size: 12px;
        font-weight: 700;
      }
  
      h5 {
        @media (max-width: 360px) {
          font-size: 16px;
        }
      }
  
      span {
        @media (max-width: 360px) {
          font-size: 13px;
        }
      }
  
      &:hover {
        .user-box {
          svg {
            animation: tada 1.5s ease infinite;
          }
        }
      }
    }
  
    .user-icon {
      border-radius: 100%;
      display: inline-block;
  
      .user-box {
        margin: 3px;
        width: 47px;
        height: 47px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
  
        @media (max-width: 1540px) {
          width: 30px;
          height: 30px;
  
          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
  
      &.primary {
        border: 1px solid $primary-color;
  
        .user-box {
          background: lighten($primary-color, 25%);
        }
      }
  
      &.success {
        border: 1px solid $success-color;
  
        .user-box {
          background: lighten($success-color, 40%);
        }
      }
    }
  }
  
  .appointment-table {
    table {
      tr {
        td {
          position: relative;
          border-top: none;
          padding: 15px 0;
          vertical-align: middle;
  
          &:first-child {
            width: 50px;
          }
  
          .status-circle {
            top: 1px;
            left: 35px;
            border-width: 3px;
          }
  
          p,
          .btn {
            font-weight: 500;
            font-size: 12px;
          }
  
          .btn {
            padding: 4px 12px;
          }
        }
  
        .img-content-box {
          a {
            color: var(--body-font-color);
            transition: 0.5s;
            display: inline-block !important;
          }
  
          span {
            display: block;
          }
        }
  
        &:hover {
          .img-content-box {
            a {
              color: var(--theme-deafult);
              transition: 0.5s;
            }
          }
        }
  
        &:first-child {
          td {
            padding-top: 0 !important;
          }
        }
  
        &:last-child {
          td {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  
    &.customer-table {
      table {
        tr {
          td {
            padding: 10px 0;
  
            img {
              min-width: 40px;
            }
          }
        }
      }
    }
  }
  
  .icon-box {
    width: 50px;
    height: 32px;
    background-color: rgba($primary-color, 0.08);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    i {
      color: var(--theme-deafult);
      font-size: 22px;
    }
  
    svg {
      stroke: var(--theme-deafult);
    }
  }
  
  .balance-card {
    display: flex;
    padding: 15px;
    border-radius: 5px;
    gap: 15px;
    transition: 0.5s;
  
    @media (max-width: 1199px) {
      gap: 8px;
    }
  
    .svg-box {
      width: 43px;
      height: 43px;
      background: $white;
      box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media (max-width: 1199px) {
        width: 35px;
        height: 35px;
      }
  
      svg {
        height: 20px;
        fill: rgba($theme-body-sub-title-color, 0.85);
  
        @media (max-width: 1199px) {
          height: 17px;
        }
      }
    }
  }
  
  
  .btn-light-secondary {
    background-color: rgba(var(--theme-secondary), 0.1);
    font-size: 12px;
    padding: 6px 12px;
    font-weight: 600;
  }
  
  //index dashboard css ends
  
  
  .crm-activity {
    >li {
      +li {
        border-top: 1px solid $light-semi-gray;
        margin-top: 10px;
        padding-top: 10px;
        h6 {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 5px;
        }
      }
      h6 {
        font-size: 14px;
      }
    }
    
    span {
      font-size: 40px;
    }
  
    ul.dates {
      h6 {
        color: $theme-body-font-color;
      }
  
      li {
        color: $theme-body-sub-title-color;
        font-size: 12px;
        display: inline-block;
        line-height: 1;
  
        +li {
          border-left: 1px solid $light-semi-gray;
          padding-left: 10px;
          margin-left: 5px;
        }
      }
    }
  }
  
  //general widget css start
  
  .fill-primary {
    fill: $primary-color;
  }
  
  .fill-secondary {
    fill: $secondary-color;
  }
  
  .fill-success {
    fill: $success-color;
  }
  
  .fill-warning {
    fill: $warning-color;
  }
  
  .fill-info {
    fill: $info-color;
  }
  
  .fill-danger {
    fill: $danger-color;
  }
  
  .ecommerce-widget {
    box-shadow: none;
    border: 1px solid #f1f3ff !important;
    border-radius: 5px;
  
    .total-num {
      color: $theme-body-font-color;
      margin: 0;
      letter-spacing: 1px;
  
      span {
        color: $black;
      }
    }
  
    .row {
      align-items: center;
    }
  
    .progress-showcase {
      margin-top: 30px;
    }
  
    span {
      color: $theme-body-sub-title-color;
      margin: 0;
      cursor: pointer;
    }
  
    .icon {
      color: $light-semi-gray;
      font-size: 40px;
    }
  
    .flot-chart-container {
      height: 100px;
      padding: 0;
      margin: 0;
      border: transparent;
    }
  
    .morris-default-style {
      display: none !important;
    }
  
    svg {
      -webkit-filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
      filter: drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3));
    }
  }
  
  .redial-social-widget {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin: 0 auto;
    background-image: linear-gradient(90deg, $light-color 50%, transparent 50%, transparent), linear-gradient(90deg, $light-gray 50%, $light-color 50%, $light-color);
  
    i {
      background-color: $white;
      height: 80px;
      width: 80px;
      border-radius: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &.radial-bar-70 {
      background-image: linear-gradient(342deg, var(--theme-deafult) 50%, transparent 50%, transparent), linear-gradient(270deg, var(--theme-deafult) 50%, $light-color 50%, $light-color);
    }
  }
  
  .browser-widget {
    &:hover {
      img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        transition: all 0.3s ease;
        transform: scale(1.1) rotate(5deg);
      }
    }
  
    img {
      height: 80px;
      filter: grayscale(100%);
      transform: scale(1) rotate(0deg);
      transition: all 0.3s ease;
    }
  
    .media-body {
      text-align: center;
      column-count: 3;
      column-rule: 1px solid $light-color;
  
      p {
        margin-bottom: 5px;
      }
  
      span {
        margin-bottom: 0;
        color: $theme-body-sub-title-color;
      }
  
      h4 {
        color: $theme-body-font-color;
        margin-bottom: 0;
        font-size: $btn-lg-font-size;
  
        span {
          color: #333333;
        }
      }
    }
  }
  
  .testimonial {
    i {
      font-size: 60px;
      color: rgba(43, 43, 43, 0.7);
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.1;
      z-index: -1;
    }
  
    p {
      color: $theme-body-sub-title-color;
      font-style: italic;
      margin-bottom: 30px;
      margin-top: 30px;
    }
  
    h5 {
      color: $theme-body-font-color;
      padding-top: 10px;
    }
  
    span {
      color: $theme-body-sub-title-color;
    }
  
    img {
      margin: 0 auto;
    }
  }
  
  //general widget css start
  .widget-charts {
    .widget-1 {
      .card-body {
        padding: 30px 25px;
      }
    }
  }
  
  //chart widget css
  .chart-widget-top {
  
    #chart-widget1,
    #chart-widget2,
    #chart-widget3 {
      margin-bottom: -14px;
  
      .apexcharts-xaxistooltip {
        display: none;
      }
    }
  
    span {
      color: $font-gray-color;
    }
  }
  
  .bar-chart-widget {
    .apexcharts-legend {
      bottom: 0 !important;
  
      .apexcharts-legend-series {
        margin: 0 10px !important;
      }
  
      .apexcharts-legend-marker {
        margin-right: 5px;
      }
    }
  
    .top-content {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  
    .earning-details {
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      letter-spacing: 1px;
  
      i {
        font-size: 230px;
        position: absolute;
        opacity: 0.1;
        right: -30px;
        top: 0;
  
        &:hover {
          transform: rotate(-5deg) scale(1.1);
          transition: all 0.3s ease;
        }
      }
    }
  
    .num {
      font-weight: 600;
  
      .color-bottom {
        color: $black;
      }
    }
  
  }
  
  .skill-chart {
    margin-bottom: -48px;
  
    .apexcharts-legend {
      .apexcharts-legend-series {
        span {
          display: block;
        }
  
        .apexcharts-legend-text {
          margin: 10px 0 20px;
        }
      }
    }
  }
  
  .progress-chart {
    margin: -11px 0 -20px;
  
    .apexcharts-canvas {
      svg {
        path,
        rect {
          clip-path: inset(1% 0% 0% 0% round 1rem);
        }
      }
    }
  }
  
  .bottom-content {
    span {
      color: $theme-body-sub-title-color;
    }
  
    .block-bottom {
      display: block;
    }
  }
  
  @keyframes am-moving-dashes {
    100% {
      stroke-dashoffset: -30px;
    }
  }
  
  @-webkit-keyframes am-pulsating {
    0% {
      stroke-opacity: 1;
      stroke-width: 0;
    }
  
    100% {
      stroke-opacity: 0;
      stroke-width: 50px;
    }
  }
  
  @keyframes am-pulsating {
    0% {
      stroke-opacity: 1;
      stroke-width: 0;
    }
  
    100% {
      stroke-opacity: 0;
      stroke-width: 50px;
    }
  }
  
  @-webkit-keyframes am-draw {
    0% {
      stroke-dashoffset: 500%;
    }
  
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes am-draw {
    0% {
      stroke-dashoffset: 500%;
    }
  
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  .serial-chart .chart-container {
    width: 100%;
    height: 500px;
  
    .lastBullet {
      animation: am-pulsating 1s ease-out infinite;
    }
  
    .amcharts-graph-column-front {
      transition: all .3s .3s ease-out;
  
      &:hover {
        fill: var(--theme-secondary);
        stroke: var(--theme-secondary);
        transition: all .3s ease-out;
      }
    }
  
    .amcharts-graph-g3 {
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dasharray: 0;
      stroke-dashoffset: 0;
      animation: am-draw 40s;
    }
  
    .amcharts-graph-g2 .amcharts-graph-stroke {
      stroke-dasharray: 3px 3px;
      stroke-linejoin: round;
      stroke-linecap: round;
      animation: am-moving-dashes 1s linear infinite;
    }
  }
  
  .chart-container {
  
    .apexcharts-xaxis-label,
    .apexcharts-yaxis-label {
      font-size: 14px !important;
      color: $font-gray-color !important;
    }
  
    .apexcharts-xaxis-annotations {
      rect {
        clip-path: inset(1% 0% 0% 0% round 0.2rem);
      }
    }
  }
  
  .crypto-chart {
    margin-left: -20px;
  
    .apexcharts-tooltip-title {
      display: none;
    }
  
    .apexcharts-tooltip-series-group {
  
      &:last-child,
      &.active {
        padding-bottom: 0;
      }
    }
  }
  
  .speed-chart {
    .chart-container {
      width: 100%;
      height: 530px;
    }
  
    .content {
      margin-top: -200px;
      margin-bottom: 20px;
  
      h4 {
        font-weight: 600;
        padding-top: 2px;
      }
    }
  }
  
  .status-widget {
    svg {
      width: 20px;
      height: 20px;
      vertical-align: text-top;
    }
  
    .card {
      .card-header {
        h5 {
          line-height: 1.38;
        }
      }
    }
  
    .status-chart {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      overflow: hidden;
    }
  }
  
  .small-chart-widget .chart-container {
    height: 311px;
    padding: 0;
    margin: 0;
    border: none;
  }
  
  .small-chart-widget {
    .card {
      .card-body {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  
  .donut-chart-widget .chart-container {
    height: 300px;
    border: 0;
    margin: 0;
    padding: 0;
  }
  
  .status-details {
    h4 {
      font-weight: 600;
  
      span {
        color: inherit;
      }
    }
  
    span {
      color: $theme-body-sub-title-color;
    }
  }
  
  .status-chart .chart-container {
    height: 200px;
    border: 0;
    padding: 0;
    margin: 0;
  }
  
  .map-chart .chart-container {
    width: 100%;
    height: 500px;
  }
  
  .serial-chart {
    width: 100%;
  }
  
  .live-products,
  .turnover,
  .uses,
  .monthly {
    height: 300px;
  }
  
  .live-products {
    .ct-series-a {
  
      .ct-area,
      .ct-point,
      .ct-line,
      .ct-bar,
      .ct-slice-donut {
        stroke: rgba(255, 255, 255, 0.77);
      }
    }
  
    .ct-label {
      fill: $white;
      color: $white;
    }
  
    .ct-grid {
      stroke: rgba(255, 255, 255, 0.7);
    }
  }
  
  .turnover,
  .uses,
  .monthly {
    .ct-series-a {
  
      .ct-area,
      .ct-point,
      .ct-line,
      .ct-bar,
      .ct-slice-donut {
        stroke: rgba(255, 255, 255, 1);
        fill: transparent;
      }
    }
  
    .ct-series-b {
  
      .ct-area,
      .ct-point,
      .ct-line,
      .ct-bar,
      .ct-slice-donut {
        stroke: rgba(255, 255, 255, 0.70);
        fill: transparent;
      }
    }
  
    .ct-series-c {
  
      .ct-area,
      .ct-point,
      .ct-line,
      .ct-bar,
      .ct-slice-donut {
        stroke: rgba(255, 255, 255, 0.40);
        fill: transparent;
      }
    }
  
    .ct-label {
      fill: $white;
      color: $white;
    }
  
    .ct-grid {
      stroke: rgba(255, 255, 255, 0.7);
    }
  }
  
  // draggable card
  #draggableMultiple {
    .ui-sortable-handle {
      .card {
        cursor: move;
      }
    }
  }
  
  // tilt page
  .tilt-showcase {
    ul {
      li {
        .line {
          padding-top: 10px;
        }
      }
    }
  
    .pre-mt {
      margin-top: 5px;
    }
  }
  
  // wow page
  .wow-title {
    h5 {
      display: flex;
      align-items: center;
      font-size: 18px;
  
      .badge {
        -webkit-text-fill-color: $white;
      }
    }
  }
  
  // alert page
  .alert-center {
    display: flex !important;
    align-items: center;
  }
  
  // box-shadow
  .box-shadow-title {
    .sub-title {
      margin: 30px 0;
    }
  }
  
  // tour page
  .hovercard {
    .info {
      .social-media {
        ul {
          li {
            padding-top: 3px;
          }
        }
      }
    }
  
    .cardheader {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  
  // sticky page
  .sticky-header-main {
    .card {
      .card-header {
        h5 {
          line-height: 35px;
  
          a {
            color: $white;
            -webkit-text-fill-color: $white;
          }
        }
      }
    }
  }
  
  // radio-checkbox-control page
  .custom-radio-ml {
    margin-left: 3px;
  }
  
  // typeahead page
  // typeahead page
  .twitter-typeahead {
    display: block !important;
  }
  
  span.twitter-typeahead {
    .league-name {
      font-size: 16px;
      padding: 6px 10px 0;
    }
  
    .tt-menu {
      float: left;
      width: 100%;
      min-width: 10rem;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: $white;
      background-clip: padding-box;
      border-radius: 0.25rem;
      -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
      border: none;
      padding: 0;
    }
  
    .tt-suggestion {
      color: $dark-editor-document;
      opacity: 0.6;
      font-size: 13px;
      padding: 6px 12px;
      border-top: 1px solid $light-semi-gray;
      background: $white;
      cursor: pointer;
  
      &:hover,
      &:focus {
        background-color: $white;
      }
  
      &.active,
      &:active {
        color: $white;
        background-color: var(--theme-deafult);
      }
    }
  }
  
  #scrollable-dropdown-menu {
    .twitter-typeahead {
      .tt-menu {
        .tt-dataset {
          max-height: 100px;
          overflow-y: auto;
        }
      }
    }
  }
  
  .chart-right {
    position: relative;
  }
  
  .balance-data {
    display: flex;
    gap: 7px; 

  
    [dir="rtl"] & { 
      right: unset;
      left: 2%; 
    } 
  
    @media (max-width:991px) {
      top: -42px;
      right: -65%;
  
      [dir="rtl"] & {
        left: -65%;
      }
    }
  
    @media (max-width: 575px) {
      display: none;
    }
  
    li {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
  
    .circle {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
  }
  
  .current-sale-container {
    padding-right: 12px;
  
    [dir="rtl"] & {
      padding-right: unset;
      padding-left: 12px;
    }
  
    >div {
      margin: -22px 0 -30px -16px;
  
      @media (max-width: 1199px) {
        margin-bottom: 0;
      }
  
      @media (max-width: 404px) {
        margin-bottom: -30px;
      }
    }
  
    .apexcharts-xaxistooltip {
      color: var(--theme-deafult);
      background: rgba($primary-color, 0.1);
      border: 1px solid var(--theme-deafult);
    }
  
    .apexcharts-xaxistooltip-bottom:before {
      border-bottom-color: var(--theme-deafult);
    }
  
    .apexcharts-tooltip.light .apexcharts-tooltip-title {
      background: rgba($primary-color, 0.1);
      color: var(--theme-deafult);
    }
  
    &.order-container {
      @media (max-width:575px) {
        padding-right: 0;
  
        [dir="rtl"] & {
          padding-left: 0;
        }
      }
  
      >div {
        @media (max-width:404px) {
          margin-bottom: 0;
        }
      }
    }
  }
  
  .recent-circle {
    min-width: 10px;
    height: 10px;
    border-radius: 100%;
    display: inline-block;
    margin-top: 5px;
  }
  
  .recent-wrapper {
    align-items: center;
  
    .order-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;
  
      @media (max-width: 1199px) {
        gap: 20px;
      }
  
      @media (max-width: 575px) {
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
      }
  
      li {
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }
    }
  
    .recent-chart {
      .apexcharts-canvas {
        .apexcharts-datalabel-label {
          @media (max-width: 1660px) {
            font-size: 15px;
          }
        }
      }
    }
  
    >div {
      @media (max-width: 1560px) and (min-width: 1400px) {
        width: 100%;
      }
  
      &:last-child {
        @media (max-width: 1560px) and (min-width: 1400px) {
          display: none;
        }
      }
    }
  }
  
  .apexcharts-series.apexcharts-radial-series {
    position: relative;
  
    &::after {
      position: absolute;
      content: '';
      background: linear-gradient(0deg, #FFFFFF 0.05%, #F4F4F4 19.91%, #F5F6F9 55.02%, #EAECF4 100%);
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      top: 0;
      left: 0;
    }
  }
  
  // bootstrap basic table page
  .card-block {
    .table-responsive {
      .table {
        caption {
          padding-left: 10px;
        }
      }
  
      .table-bordered {
        td {
          vertical-align: middle;
        }
      }
    }
  
    .table-border-radius {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  
    .default-checkbox-align {
      #checkbox1 {
        margin-right: 10px;
      }
  
      #radio {
        margin-right: 5px;
      }
    }
  }
  
  // datatable styling page
  #example-style-3_wrapper {
    #example-style-3 {
      tfoot {
        border-top: 2px solid $light-semi-gray;
      }
    }
  }
  
  // chartjs page
  .chart-vertical-center {
    display: flex;
    justify-content: center;
  
    #myDoughnutGraph,
    #myPolarGraph {
      width: auto !important;
    }
  }
  
  // ckeditor page
  .cke_focus {
    padding: 15px;
    margin-top: 13px;
  }
  
  // ace-code-editor page
  #editor {
    .ace_scroller {
      .ace_content {
        .ace_layer {
          .ace_print-margin {
            visibility: hidden !important;
          }
        }
      }
    }
  }
  
  // Helper classes page css
  .helper-classes {
    padding: 30px;
    margin-bottom: 0;
    white-space: pre-line;
  }
  
  // starter kit page css
  .starter-kit-fix {
    .page-body {
      margin-bottom: 52px;
    }
  }
  
  .starter-main {
    .card-body {
      p {
        font-size: 14px;
      }
  
      ul {
        padding-left: 30px;
        list-style-type: disc;
        margin-bottom: 15px;
      }
  
      h5 {
        font-size: 18px;
      }
  
      pre {
        white-space: pre-line;
        padding: 30px;
      }
  
      .alert-primary {
        &.inverse {
          &:before {
            top: 32px;
          }
        }
      }
    }
  
    .alert {
      background-color: rgba($primary-color, 0.2) !important;
  
      i {
        display: flex;
        align-items: center;
      }
    }
  }
  
  //typography//
  footer {
    &.blockquote-footer {
      bottom: unset;
    }
  }
  
  // index responsive css

  @media only screen and (max-width: 767px) {
    .ecommerce-banner{
      height: 210px;
    }
    .footer-fix {
      .pull-right {
        float: none !important; 
        text-align: center !important; 
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .notification {
      li {
        padding-bottom: 20px;
      }
      .card {
        .card-body {
          .d-flex {
            .w-100 {
              p {
                margin: 0;
              }

              h6 {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .notification {
      li {
        padding-bottom: 15px;
      }
    }
    .media-dropdown {
      display: block;
  
      .media-body {
        margin-bottom: 15px;
      }
    }
    .total-chart {
      span{
        font-size: 12px !important;
      }
    }
  }
  
  @media only screen and (max-width: 360px) {
    .footer {
      padding-left: 0 !important;
      padding-right: 0 !important;
  
      p {
        font-size: 12px;
      }
    }
  
    .appointment-table {
      table {
        tr {
          td {
            min-width: 103px;
  
            &:first-child {
              min-width: 50px;
            }
  
            &:nth-child(2) {
              min-width: 120px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 425px) {
    .sales-chart{
      .sales-chart-dropdown-select{
        .card-header-right-icon{
          &.online-store{
            .dropdown{
              display: none;
            }
          }
        }
      }
    }
    .search-form{
      input{ 
        padding-left: 50px;
      }
    } 
  }
 
  @media (max-width: 1399px) {
    .special-Offer {
      h4{
        font-size: 18px;
      }
      .offer-contain{
        p{
          text-overflow:ellipsis;
          overflow:hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
    .total-chart{
      .data-grow{
        span{
          display: none !important;
        }
        svg{
          display: none !important;
        }
      }
    }
    .total-revenue{
      span{
        font-size: 12px;
      }
      a{
        display: none !important;
      }
    }
    .total-revenue{
      span{
        font-size: 12px;
      }
      a{
        display: none !important;
      }
    }
    .table-order{
      .latest-orders{
        thead{
          tr{ 
            th{ 
              &:nth-child(3){ 
                display: block !important; 
              } 
              &:last-child{
                display: block !important;
              }
            }
          }
        }
        tbody{ 
          tr{
            td{
              &:nth-child(3){
                display: block !important; 
              }
              &:last-child {
                display: block !important;
              }
            }
          }
        }
      }
      .order-table{
        thead{
          tr{
            th{
              &:nth-child(4){
                display: block;
              }
            }
          }
        }
      }
    }

    .total-chart{
      span { 
        font-size: 15px; 
        font-weight: 500 !important;
      }
    } 
    
  }
  @media (max-width:1299px) {
    .special-Offer { 
      h4{
        font-size: 16px;
      }
    }
  }
  @media (max-width: 1199px) {
    .special-Offer {
      h4{
        font-size: 18px;
      }
    }
    .total-chart{
      .data-grow{
        span{
          display: block !important;
        }
        svg{
          display: block !important;
        }
      }
    }
    .total-revenue{
      a{
        display: block !important;
      }
    }
    .total-revenue{
      a{
        display: block !important;
      }
    }
  }
  @media (max-width:1599px) {
    .table-order{
      .latest-orders{
        thead{
          tr{
            th{
              &:nth-child(2){ 
                min-width: 186px;
              }
              &:nth-child(3){ 
                display: none;
              } 
              &:last-child{ 
                display: none;
              } 
            }
          } 
        }
        tbody{
          tr{
            td{     
              &:nth-child(3){
                display: none;
              }
              &:last-child { 
                display: none; 
              }
            }
          }
        }
      } 
    }
    .revenueproduct{
      .apexcharts-canvas{
        .apexcharts-legend{
          display: none;
        }
      }
      margin-bottom: -0px;
    }
    .card{
      .card-body{
        .product-costing {
          .costing-span{
            font-size: 10px ;
            font-weight: 400 ;
          }
          .product-icon{
            height: 40px;
            width: 40px;
            svg{
              height: 20px;
              margin: 10px;
              width: 21px;
            }
          }
        }
      }
    }
  }
  @media (max-width:1799px) {
    .balance-data{
      li{
        &:last-child{
          display: none !important; 
        }
      }
    }
    .total-chart{
      .data-grow{
        span{
          max-width: 235px;
          display:-webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden; 
        } 
      } 
    }
  } 
  @media (max-width:1865px) {
    .table-order{ 
      .order-table{
        thead{
          tr{ 
            th{
              &:nth-child(2) {  
                min-width: 115px !important;
              }
              &:nth-child(3) {  
                min-width: 80px !important; 
              }
              &:nth-child(4) {
                display: none !important;
              }
            }
          }
        }
        tbody{
          tr { 
            td { 
              &:nth-child(4) {
                display: none !important; 
              } 
            } 
          }
        }
      }
      .latest-orders{
        thead{
          tr{ 
            th{
              &:nth-child(3){ 
                display: none ;
              }
            }
          }
        }
        tbody{
          tr{
            td{
              &:nth-child(3){
                display: none ;
              }
            }
          }
        }
      }
    }
  }

/**=====================
  3.13 Dashboard_default CSS Ends
==========================**/