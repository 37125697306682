/*! -----------------------------------------------------------------------------------

    Template Name: Riho Admin
    Template URI: https://admin.pixelstrap.net/riho/template/
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

    1.Base css
        1.1 Reset CSS
        1.2 Typography CSS
    
    2.Components css
        2.1 According CSS
        2.2 Alert CSS
        2.3 Avatars CSS
        2.4 Badge CSS
        2.5 Bookmark CSS
        2.6 Breadcrumb CSS
        2.7 Basic-card CSS
        2.8 Builders CSS
        2.9 Buttons CSS
        2.10 Card CSS
        2.11 Color CSS
        2.12 Datatable CSS
        2.13 Datapicker CSS
        2.14 Dropdown CSS
        2.15 Form-builder CSS
        2.16 Form-input CSS
        2.17 Form-wizard CSS
        2.18 Forms CSS
        2.19 Icons CSS
        2.20 List CSS
        2.21 Loader CSS 
        2.22 Modal CSS
        2.23 Popover CSS
        2.24 Print CSS
        2.25 Radio CSS
        2.26 Ribbon CSS
        2.27 Range-slider CSS
        2.28 Switch CSS
        2.29 Tab CSS
        2.30 Table CSS
        2.31 Toasts CSS
        2.32 Touchspin CSS
        2.33 Tour CSS
        2.34 Tree CSS
        2.35 Typeahead-search CSS
        2.36 Scrollbar CSS

    3. Pages
        3.1 Blog CSS
        3.2 Bookmark-app CSS
        3.3 Cart CSS
        3.4 Chart CSS
        3.5 Chat CSS
        3.6 Checkout CSS
        3.7 Comingsoon CSS
        3.8 Contacts CSS
        3.9 Dashboard_2 CSS
        3.10 Dashboard_3 CSS 
        3.11 Dashboard_4 CSS
        3.12 Dashboard_5 CSS
        3.13 Dashboard_default CSS
        3.14 Ecommerce CSS
        3.15 Email-application CSS
        3.16 Errorpage CSS
        3.17 Faq CSS
        3.18 File CSS
        3.19 Gallery CSS
        3.20 Helper CSS
        3.21 Internationalization CSS
        3.22 Job-search CSS
        3.23 Jsgrid CSS
        3.24 Kanban CSS
        3.25 Knowledgebase CSS
        3.26 Landing CSS
        3.27 Language CSS
        3.28 Learning CSS
        3.29 Login CSS
        3.30 Megaoption CSS
        3.31 Order-history CSS
        3.32 Page CSS
        3.33 Pricing CSS
        3.34 Progress CSS
        3.35 Projectlist CSS
        3.36 Rating CSS
        3.37 Social-app CSS
        3.38 Sweet-alert CSS
        3.39 Task CSS
        3.40 Timeline-v CSS
        3.41 User-profile CSS
        3.42 Wishlist CSS
        3.43 Dashboard_6.CSS
        3.44 Dashboard_7 CSS
        3.45 Dashboard_8.CSS

    4. Themes
        4.1. Dark CSS
        4.2 Theme-customizer CSS
        4.3 Update CSS

    5. Layout
        5.1 Footer CSS
        5.2 Grid CSS
        5.3 Header CSS
        5.4 Navs CSS
        5.5 Search CSS
        5.6 Select2 CSS
        5.7 Sidebar CSS
        5.8 Rtl CSS
        5.9 Box-layout CSS 

 */
// *** utils ***
@import "utils/variables";

:root {
    --theme-deafult: #006666;
    --theme-secondary: #FE6A49;
    --light-background: rgba(242, 243, 247, 0.7);
    --body-font-color: #86909C;
    --chart-border: #E6E9EB;
    --recent-chart-bg: #FCFCFD; 
    --light-bg: #EEF1F6;  
    --white: #fff;
    --light2: #F5F6F9;
    --sidebar-border: rgba(0, 0, 0, 0.1);
    --chart-text-color: rgba(82, 82, 108, 0.8);
    --recent-dashed-border: rgba(82, 82, 108, 0.3);
    --chart-dashed-border: rgba(82, 82, 108, 0.2);
    --chart-progress-light: rgba(82, 82, 108, 0.1);
    --recent-box-bg: #F2F4F7;
    --recent-border: rgba(97, 101, 122, 0.25);
    --course-light-btn: #F2F3F7;
    --course-bg: #F7F7F7;
    --balance-profie-bg: #E0DFEF;
    --view-grid-bg: #ECF3FA80;
    --view-border-marker: #cfcdfc;
    --light-slate: #F5F6F9; 
    --text-gray: rgba(82, 82, 108, 0.8);
    --product-shadow: 0px 1px 0px rgba(82, 82, 108, 0.15);
    --bs-heading-color: #051A1A;
    --bs-card-color: #051A1A;

    --fc-button-bg-color: var(--theme-deafult);
    --fc-button-active-bg-color: var(--theme-deafult);
    --fc-button-active-border-color: var(--theme-deafult);
    --fc-button-border-color: var(--theme-deafult);
    --fc-button-hover-bg-color: var(--theme-deafult);
    --fc-button-hover-border-color: var(--theme-deafult);

    // rating 
    --normal: #ECEAF3; 
    --normal-shadow: #D9D8E3;
    --normal-mouth: #9795A4;
    --normal-eye: #595861;
    --active: #F8DA69;
    --active-shadow: #F4B555;
    --active-mouth: #F05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --active-shadow-angry: #e94f1d;

    // Tagify
    /* Do not show the "remove tag" (x) button when only a single tag remains */
    .customLook {
        --tag-bg: var(--theme-deafult);
        --tag-hover: var(--theme-secondary);
        --tag-text-color: var(--white);
        --tags-border-color: silver;
        --tag-border-radius: 25px;
        --tag-text-color--edit: #111;
        --tag-remove-bg: var(--tag-hover);
        --tag-pad: .6em 1em;
        --tag-inset-shadow-size: 1.35em;
        --tag-remove-btn-bg--hover: #000;
        --tagify-dd-color-primary: var(var(--theme-deafult));
        --tagify-dd-bg-color: var(--course-light-btn);
    }
}

.table{
    --bs-body-bg: auto;
}

body[class='dark-only'] {
    --light-background: #1A1C23;
    --recent-chart-bg: #262932;
    --light-bg: #1d1e26;
    --chart-text-color: #86909C; 
    --white: #262932;
    --sidebar-border: rgba(255, 255, 255, 0.1);
    --light2: #1d1e26;
    --chart-border: #1A1C23;
    --recent-box-bg: #1d1e26;
    --recent-border: #374558;
    --body-font-color: rgba(255, 255, 255); 
    --course-light-btn: var(--light-bg);
    --view-grid-bg: var(--light-bg);
    --view-border-marker: #2c2c45d4;
    --light-slate: #1c1d26;
    --text-gray: rgba(153, 153, 169, 0.8);
    --product-shadow: 0px 1px 0px rgb(55 70 87);
    --bs-heading-color: rgb(255, 255, 255);
    --bs-card-color: rgba(255, 255, 255, 0.7);
}

body[class="rtl dark-only"] {
    --light-background: #1A1C23;
    --recent-chart-bg: #262932;
    --light-bg: #1d1e26;
    --chart-text-color: #86909C;
    --white: #262932;
    --sidebar-border: rgba(255, 255, 255, 0.1);
    --light2: #1d1e26;
    --chart-border: #1A1C23;
    --recent-box-bg: #1d1e26;
    --recent-border: #374558;
    --body-font-color: rgba(255, 255, 255);
    --course-light-btn: var(--light-bg);
    --view-grid-bg: var(--light-bg);
    --view-border-marker: #2c2c45d4;
    --light-slate: #1c1d26;
    --text-gray: rgba(153, 153, 169, 0.8);
    --product-shadow: 0px 1px 0px rgb(55 70 87);
    --bs-heading-color: rgb(255, 255, 255);
    --bs-card-color: rgba(255, 255, 255, 0.7);

}

body[class="dark-only modal-open"] {
    --light-shade-primary: rgba(48, 126, 243, 0.2);
    --light-slate: #1c1d26;  
}

body[class="rtl dark-only modal-open"] {
    --light-shade-primary: rgba(48, 126, 243, 0.2);
    --light-slate: #1c1d26;
}
body.dark-only.box-layout {
    --light-background: #1A1C23;
    --recent-chart-bg: #262932;
    --light-bg: #1d1e26;
    --white: #262932;
    --chart-text-color: #86909C;
    --sidebar-border: rgba(255, 255, 255, 0.1);
    --light2: #1d1e26;
    --chart-border: #1A1C23;
    --recent-box-bg: #1d1e26;  
    --recent-border: #374558;
    --body-font-color: rgba(255, 255, 255);
    --course-light-btn: var(--light-bg);
    --view-grid-bg: var(--light-bg);
    --view-border-marker: #2c2c45d4;
    --bs-heading-color: rgb(255, 255, 255);
    --bs-card-color: rgba(255, 255, 255 , 0.7);
}


// *** base ***
@import "base/reset";
@import "base/typography";

//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/basic-card";
// @import "components/builders";
@import "components/buttons";
@import "components/card";
@import "components/color";
@import "components/datatable";
@import "components/datepicker";
@import "components/dropdown";
@import "components/form-builder";
@import "components/form-input";
@import "components/form-wizard";
@import "components/forms";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/modal";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/range-slider";
@import "components/switch";
@import "components/tab";
@import "components/table";
@import "components/toasts";
@import "components/touchspin";
@import "components/tour";
@import "components/tree";
@import "components/typeahead-search";
@import "components/scrollbar";
@import "components/jnputmask";
@import "components/calendar";

//	*** pages ***

@import "pages/blog";
@import "pages/add-product";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/dashboard_2";
@import "pages/dashboard_3";
@import "pages/dashboard_default";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/letter_box";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/gallery";
@import "pages/helper";
@import "pages/internationalization";
@import "pages/job-search";
@import "pages/jsgrid";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/landing";
@import "pages/language";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/order-history";
@import "pages/page";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/rating";
@import "pages/social-app";
@import "pages/sweet-alert";
@import "pages/task";
@import "pages/todo";
@import "pages/timeline-v";
@import "pages/user-profile";
@import "pages/wishlist";


/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/theme-customizer";
@import "themes/update";
// *** layout *** 

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/navs";
@import "layout/search"; 
@import "layout/select2";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";